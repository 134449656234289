<template>
  <div class="spinner-container" v-if="visible">
    <circle2></circle2>
  </div>
</template>

<script>
import {Circle2} from "vue-loading-spinner";

export default {
  name: "spinner",
  components: {
    Circle2,
  },
  props: ['visible'],
}
</script>

<style scoped>
.spinner-container {
    height: 100%;
    width: 100%;
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    z-index: 99999;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.spinner {
  border-color: white var(--primary-color) var(--primary-color) !important;
  width: 80px !important;
  height: 80px !important;
  border-width: 5px !important;
}
</style>